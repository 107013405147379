import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/Header.css";
import Logo from "../img/gnblogo.png";
import LogoWhite from "../img/gnblogo-white.png";
import { FaBars, FaTimes } from "react-icons/fa";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const location = useLocation();

  const changeFontSize = (size) => {
    document.documentElement.style.fontSize = `${size}px`;
  };

  useEffect(() => {
    const navbar = document.getElementById("menu");
    const navFontColors = document.querySelectorAll(".navOption a");

    const handleScroll = () => {
      const newScrollPosition = window.scrollY;
      setScrollPosition(newScrollPosition);

      if (newScrollPosition > 0 || (location.pathname !== "/" && "/obsluga")) {
        navbar.classList.add("active");

        navFontColors.forEach((navFontColor) => {
          navFontColor.classList.add("active");
        });
      } else {
        navbar.classList.remove("active");

        navFontColors.forEach((navFontColor) => {
          navFontColor.classList.remove("active");
        });
      }
    };

    // Dodajemy klasę .active na innych stronach niż główna
    if (location.pathname !== "/" && "/obsluga") {
      navbar.classList.add("active");
      navFontColors.forEach((navFontColor) => {
        navFontColor.classList.add("active");
      });

      // Zwijamy nawigację automatycznie po przejściu na inną stronę
      setMenuOpen(false);
    } else {
      if (scrollPosition === 0) {
        navbar.classList.remove("active");

        navFontColors.forEach((navFontColor) => {
          navFontColor.classList.remove("active");
        });
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, scrollPosition]);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavLogoClick = () => {
    // Po kliknięciu w logo zwijamy nawigację
    setMenuOpen(false);

    // Jeśli jesteśmy na stronie głównej, przewijamy do góry strony
    if (location.pathname === "/" && "/obsluga") {
      window.scrollTo(0, 0);
    }
  };

  return (
    <header>
      <nav id="menu">
        <Link to="/" className="navLogo" onClick={handleNavLogoClick}>
          <img
            id="logo"
            src={
              location.pathname !== "/" && "/obsluga"
                ? Logo
                : scrollPosition === 0
                ? menuOpen
                  ? Logo
                  : LogoWhite
                : Logo
            }
            alt="Logo"
          />
        </Link>
        <ul className={`navOptions ${menuOpen ? "active" : ""}`}>
          <li
            className={`navOption ${
              location.pathname !== "/" && "/obsluga" ? "active" : ""
            }`}
          >
            <Link to="/informacje-o-postepowaniu">
              Informacje o&nbsp;postępowaniu
            </Link>
          </li>
          <li
            className={`navOption ${
              location.pathname !== "/" && "/obsluga" ? "active" : ""
            }`}
          >
            <Link to="/centrum-dokumentow">Dokumenty</Link>
          </li>
          {/* <li
            className={`navOption ${location.pathname !== "/" ? "active" : ""}`}
          >
            <Link to="/pomoc">Pomoc</Link>
          </li> */}
          <li
            className={`navOption ${
              location.pathname !== "/" && "/obsluga" ? "active" : ""
            }`}
          >
            <Link to="/kontakt">Kontakt</Link>
          </li>
          <li className="navOption">
            <a className="banking" href="https://secure.gnbbank.pl">
              Bankowość internetowa
            </a>
          </li>
        </ul>
        <div
          className={`mobileNav ${menuOpen ? "active" : ""}`}
          onClick={handleMenuClick}
        >
          <i
            className={`fa-solid ${
              location.pathname !== "/" && "/obsluga"
                ? "fa-bars"
                : menuOpen
                ? "fa-xmark"
                : "fa-bars"
            }`}
            style={{
              color:
                scrollPosition === 0 &&
                location.pathname === "/" &&
                "/obsluga" &&
                !menuOpen
                  ? "#fff" // Biała ikona na stronie głównej przy scrollu 0
                  : "var(--coal-black)", // Czarna ikona na innych stronach
            }}
          ></i>

          <ul className={`fontSizeControls`}>
            <li
              className={`navOption ${
                location.pathname !== "/" && "/obsluga" ? "active" : ""
              }`}
              onClick={() => changeFontSize(8)}
            >
              <a className="firstFontChanger">A</a>
            </li>
            <li
              className={`navOption ${
                location.pathname !== "/" && "/obsluga" ? "active" : ""
              }`}
              onClick={() => changeFontSize(9.5)}
            >
              <a className="secondFontChanger">A</a>
            </li>
            <li
              className={`navOption ${
                location.pathname !== "/" && "/obsluga" ? "active" : ""
              }`}
              onClick={() => changeFontSize(11)}
            >
              <a className="thirdFontChanger">A</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
