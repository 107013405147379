import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./css/App.css";
import Home from "./pages/Home";
import HomeAlert from "./pages/HomeAlert";
import Bankruptcy from "./pages/Bankruptcy";
import BankruptcyAlerts from "./pages/BankruptcyAlerts";
import Documents from "./pages/Documents";
import Help from "./pages/Help";
import Contact from "./pages/Contact";

import DebtRestructuring from "./pages/DebtRestructuring";
import BorrowerSupportFund from "./pages/BorrowerSupportFund";
import IndicesAndBaseRates from "./pages/IndicesAndBaseRates";
import ComplaintForm from "./pages/ComplaintForm";
import ContactForm from "./pages/ContactForm";
import Rodo from "./pages/Rodo";
import Cookies from "./pages/Cookies";
import InformationBmr from "./pages/InformationBmr";
import BmrFaq from "./pages/BmrFaq";

import ExchangeRates from "./pages/ExchangeRates";
import ExchangeRatesNobleBank from "./pages/ExchangeRatesNobleBank";

import Header from "./components/Header";
import CookiesBanner from "./components/CookiesBanner";
import Footer from "./components/Footer";

function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <Router>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/obsluga" element={<Home />} />
        <Route
          path="/komunikat-dla-klientow-getin-noble-bank-sa"
          element={<HomeAlert />}
        />

        <Route path="/kursy-walut" element={<ExchangeRates />} />
        <Route
          path="/kursy-walut-noble-bank"
          element={<ExchangeRatesNobleBank />}
        />

        <Route
          path="/fundusz-wsparcia-kredytobiorcow"
          element={<BorrowerSupportFund />}
        />
        <Route
          path="/indeksy-oraz-stawki-bazowe"
          element={<IndicesAndBaseRates />}
        />
        <Route
          path="/informacje-o-wskaznikach-referencyjnych"
          element={<InformationBmr />}
        />
        <Route
          path="/informacje-o-wskaznikach-referencyjnych/pytania-i-odpowiedzi-do-aneksow-bmr"
          element={<BmrFaq />}
        />

        <Route path="/rodo" element={<Rodo />} />

        <Route path="/informacje-o-postepowaniu" element={<Bankruptcy />} />
        <Route
          path="/informacje-o-postepowaniu/komunikaty-syndyka"
          element={<BankruptcyAlerts />}
        />
        <Route path="/pomoc" element={<Help />} />
        <Route path="/centrum-dokumentow" element={<Documents />} />
        <Route path="/kontakt" element={<Contact />} />

        <Route path="/kontakt/zloz-reklamacje" element={<ComplaintForm />} />
        <Route path="/kontakt/formularz-kontaktowy" element={<ContactForm />} />

        <Route
          path="/restrukturyzacja-zadluzenia"
          element={<DebtRestructuring />}
        />
        <Route path="/cookies" element={<Cookies />} />
      </Routes>
      <CookiesBanner />
      <Footer />
      <ScrollToTop />
    </Router>
  );
}

export default App;
