import React from "react";
import AlertCard from "./AlertCard";

function AlertCardBankruptcy() {
  const cardContent001 = (
    <li className="alertCard one">
      <div className="cardText">
        <p>
          Szanowni Państwo,
          <br></br>
          <br></br>
          uprzejmie informujemy, że&nbsp;funkcjonuje dotychczasowy numer
          rachunku bankowego służący do spłaty rat i&nbsp;innych należności
          wynikających z&nbsp;umowy o&nbsp;kredyt / pożyczkę denominowaną lub
          indeksowaną do franka szwajcarskiego (CHF) oraz z&nbsp;umowę
          o&nbsp;kredyt hipoteczny / pożyczkę zabezpieczoną hipoteką wiążącą
          denominowaną lub indeksowane do waluty obcej.
          <br></br>
          Jednocześnie w&nbsp;nowej Platformie informacyjnej Bankowość
          Internetowa GNB (gnbbank.pl) zostały udostępnione już nowe rachunki
          bankowe służące do spłaty rat i&nbsp;innych należności w&nbsp;procesie
          postępowania upadłościowego Getin Noble Banku SA w&nbsp;upadłości.
        </p>
      </div>
      <p className="cardDate">Opublikowano 03.10.2023</p>
    </li>
  );

  const cardContent002 = (
    <li className="alertCard two">
      <div className="cardText">
        <p>
          Syndyk masy upadłości Getin Noble Bank S.A. w&nbsp;upadłości
          informuje, że wpłaty zryczałtowanych kosztów postępowania
          upadłościowego wynikających z&nbsp;dokonania zgłoszenia wierzytelności
          po terminie, o&nbsp;których mowa w&nbsp;art.&nbsp;235 ust.&nbsp;1
          ustawy Prawo upadłościowe należy dokonywać na rachunek bankowy
          o&nbsp;numerze: 72 8436 0003 0000 0026 7436 0007.
        </p>
        <p>
          <span className="bold underline">
            Celem usprawnienia procesu podaje się dane do wykorzystania przy
            wykonywaniu przelewu:
          </span>
        </p>
        <p>
          <span className="bold">ODBIORCA:</span> MARCIN KUBICZEK SYNDYK MASY
          UPADŁOŚCI GETIN NOBLE BANK&nbsp;S.A. W&nbsp;UPADŁOŚCI
        </p>
        <p>
          <span className="bold">NUMER KONTA ODBIORCY:</span> 72 8436 0003 0000
          0026 7436 0007
        </p>
        <p>
          <span className="bold">TYTUŁ:</span> ZRYCZAŁTOWANY KOSZT POSTĘPOWANIA
          WA1M/GUp-Zw/44/2023 [jeżeli przelewu dokonuje pełnomocnik wierzyciela
          w&nbsp;tym miejscu należy również wskazać dane wierzyciela]
        </p>
        <p>
          <span className="bold">KWOTA:</span> 1 119,34 PLN
        </p>
      </div>
      <p className="cardDate">Opublikowano w styczniu 2024 r.</p>
    </li>
  );

  const cardContent003 = (
    <li className="alertCard three">
      <div className="cardText">
        <p>
          Szanowni Państwo,
          <br></br>
          <br></br>
          syndyk masy upadłości Getin Noble Bank S.A. w upadłości informuje,
          że&nbsp;wszelkie informacje o&nbsp;wydanych postanowieniach przez Sąd
          w&nbsp;przedmiocie zawieszenia płatności rat kredytów powinny być
          przesyłane na adres e-mail:{" "}
          <a className="gold" href="mailto:zabezpieczeniagnb@kubiczekm.com">
            zabezpieczeniagnb@kubiczekm.com
          </a>{" "}
          wraz z&nbsp;kopią wydanego orzeczenia.
        </p>
      </div>
      <p className="cardDate">Opublikowano 18.03.2024 r.</p>
    </li>
  );

  return (
    <>
      <AlertCard content={cardContent003} />
      <AlertCard content={cardContent002} />
      <AlertCard content={cardContent001} />
    </>
  );
}

export default AlertCardBankruptcy;
