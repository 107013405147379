import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import ScrollReveal from 'scrollreveal';

import '../css/CookiesBanner.css';

function CookiesBanner() {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  React.useEffect(() => {
    ScrollReveal().reveal(".cookiesBanner", {
      duration: 1000,
      delay: 1000,
    });

  }, [])

  return isVisible ? (
    <div className="cookiesBanner">
        <div>
    <h4>Używamy plików Cookies!</h4>
      <p>
         Strona korzysta z&nbsp;plików Cookies aby zapewnić
        Państwu najlepsze warunki korzystania z&nbsp;serwisu oraz aby analizować ruch
        w&nbsp;witrynie. Pliki Cookies można wyłączyć zmieniając ustawienia w&nbsp;przeglądarce. Więcej informacji znajdą Państwo w{' '}
        <Link to="/Cookies">
          <span className="underline">Polityce Cookies</span>
        </Link>.
      </p>
      </div>
      <FaTimes
        className="closeBtn"
        onClick={handleClose}
      />
    </div>
  ) : null;
}

export default CookiesBanner;
