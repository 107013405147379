import React, { useState } from "react";
import "../css/Documents.css";
import Accordion from "../components/Accordion";
import DocumentLink from "../components/DocumentLink";
import { useScrollReveal } from "../hooks/useScrollReveal";

import CreditCar01 from "../doc/documents_credit-car/01_tabela-oplat-i-prowizji-getin-noble-banku-sa-dla-konsumentow-kredyty-samochodowe.pdf";
import CreditCar02 from "../doc/documents_credit-car/02_tabela-oplat-i-prowizji-getin-noble-banku-sa-dla-klientow-firmowych.pdf";
import CreditCar03 from "../doc/documents_credit-car/03_tabela-oplat-i-prowizji-getin-noble-bank-sa-dla-umow-zawartych-od-01012021r-przez-osoby-fizyczne-prowadzace-dzialalnosc-gospodarcza-w-tym-wspolnikow-spolek-cywilnych.pdf";
import CreditMortgage01 from "../doc/documents_credit-mortgage/01-dyspozycja-calkowitej-przedterminowej-splaty.pdf";
import CreditMortgage02 from "../doc/documents_credit-mortgage/02_dyspozycja-czesciowej-przedterminowej-splaty-2.pdf";
import CreditMortgage03 from "../doc/documents_credit-mortgage/03_informacje-o-wnioskodawcy-osoba-fizyczna-2.pdf";
import CreditMortgage04 from "../doc/documents_credit-mortgage/04_informacje-o-wnioskodawcy-spolka-2.pdf";
import CreditMortgage05 from "../doc/documents_credit-mortgage/05_karta-informacyjna-poreczyciela-osoba-fizyczna-2.pdf";
import CreditMortgage06 from "../doc/documents_credit-mortgage/06_kosztorys-prac-budowlanych-wraz-z-harmonogramem-2.pdf";
import CreditMortgage07 from "../doc/documents_credit-mortgage/07_oswiadczenie-klienta-do-pit-2.pdf";
import CreditMortgage08 from "../doc/documents_credit-mortgage/08_oswiadczenie-kredytobiorcy-dokumentacja-fotograficzna-2.pdf";
import CreditMortgage09 from "../doc/documents_credit-mortgage/09_oswiadczenie-kredytobiorcy-o-zamieszkiwaniu-kredytowanej-nieruchomosci-2.pdf";
import CreditMortgage10 from "../doc/documents_credit-mortgage/10_oswiadczenie-kredytobiorcy-o-zawarciu-lokaty-2.pdf";
import CreditMortgage11 from "../doc/documents_credit-mortgage/11_oswiadczenie-o-aktualnie-posiadanych-zobowiazaniach-i-kosztach-utrzymania-2.pdf";
import CreditMortgage12 from "../doc/documents_credit-mortgage/12_oswiadczenie-o-sytuacji-finansowej-2.pdf";
import CreditMortgage13 from "../doc/documents_credit-mortgage/13_oswiadczenie-wlasciciela-nieruchomosci-2.pdf";
import CreditMortgage14 from "../doc/documents_credit-mortgage/14_pelnomocnictwo-2.pdf";
import CreditMortgage15 from "../doc/documents_credit-mortgage/15_umowa-cesji-2.pdf";
import CreditMortgage16 from "../doc/documents_credit-mortgage/16_wniosek-do-rady-funduszu-o-odroczenierozlozenie-na-ratyumorzenie-w-calosciumorzenie-w-czesci-naleznosci-z-tytulu-udzielonego-wsparciapozyczki-na-splate-zadluzenia.pdf";
import CreditMortgage17 from "../doc/documents_credit-mortgage/17_wniosek-kredytobiorcy-o-dodatkowa-weryfikacje-przez-rade-funduszu-wsparcia-kredytobiorcow-2.pdf";
import CreditMortgage18 from "../doc/documents_credit-mortgage/18_wniosek-o-sporzadzenie-zaswiadczeniaopinii-2.pdf";
import CreditMortgage19 from "../doc/documents_credit-mortgage/19_wniosek-o-uzupelnienie-kaucji-2.pdf";
import CreditMortgage20 from "../doc/documents_credit-mortgage/20_wniosek-o-zmiane-danych-kontaktowych-2.pdf";
import CreditMortgage21 from "../doc/documents_credit-mortgage/21_wniosek-o-zmiane-danych-kontaktowych-firma-2.pdf";
import CreditMortgage22 from "../doc/documents_credit-mortgage/22_wniosek-o-zmiany-warunkow-umowy-2.pdf";
import CreditMortgage23 from "../doc/documents_credit-mortgage/23_zaswiadczenie-od-pracodawcy-2.pdf";
import CreditMortgage24 from "../doc/documents_credit-mortgage/24_zestawienie-kosztow-remontu-modernizacji-wykonczenia-2.pdf";
import CreditMortgage25 from "../doc/documents_credit-mortgage/25_zgody-2.pdf";
import CreditMortgage26 from "../doc/documents_credit-mortgage/26_zlecenie-inspekcji-nieruchomosci-2.pdf";
import CreditMortgage27 from "../doc/documents_credit-mortgage/27_wniosek-o-zmiane-warunkow-zwrotu-wsparcia-udzielonego-przed-dniem-15-maja-2024-r.pdf";
import CreditMortgage28 from "../doc/documents_credit-mortgage/28_wniosek-o-udzielenie-wsparcia-promesy-lub-pozyczki-na-splate-zadluzenia-dla-kredytobiorcy-ktory-zaciagnal-kredyt-mieszkaniowy-i-znajduje-sie-w-trudnej-sytuacji-finansowej.pdf";
import CreditMortgage29 from "../doc/documents_credit-mortgage/29_regulamin-kredytowania-hipotecznego-getin-noble-bank-sa.pdf";
import CreditMortgage30 from "../doc/documents_credit-mortgage/30_regulamin-kredytu-hipotecznego.pdf";
import CreditMortgage31 from "../doc/documents_credit-mortgage/31_regulamin-promocji-latwa-splata-zwanej-dalej-promocja-dla-posiadaczy-konta-hipo-i-konta-hipo-plus-gnb.pdf";
import CreditMortgage32 from "../doc/documents_credit-mortgage/32_regulamin-udzielania-i-obslugi-kredytow-hipotecznych-dla-klientow-indywidualnych-w-getin-noble-bank-sa.pdf";
import CreditMortgage33 from "../doc/documents_credit-mortgage/33_regulamin-udzielania-kredytow-pozyczek-finansowych-getin-noble-bank-sa.pdf";
import CreditMortgage34 from "../doc/documents_credit-mortgage/34_tabela-oplat-i-prowizji-getin-noble-bank-sa-dotyczaca-kredytow-i-pozyczek-hipotecznych-i-finansowych.pdf";
import Investments01 from "../doc/documents_investments/01_regulamin-swiadczenia-uslug-w-zakresie-instrumentow-finansowych-i-produktow-finansowych-obowiazujacy-do-2102022-r-gnb.pdf";
import Investments02 from "../doc/documents_investments/02_regulamin-swiadczenia-uslug-w-zakresie-instrumentow-finansowych-i-produktow-finansowych-obowiazujacy-od-18102022-r-do-18112022-r.pdf";
import Investments03 from "../doc/documents_investments/03_regulamin-swiadczenia-uslug-w-zakresie-instrumentow-finansowych-i-produktow-finansowych-obowiazujacy-od-3102022-r-do-17102022-r.pdf";
import Complaints01 from "../doc/documents_ complaints/01_proces-obslugi-reklamacji-dla-klientow-getin-noble-bank-sa.pdf";
import Complaints02 from "../doc/documents_ complaints/02_formularz-reklamacji-skrocony.pdf";
import Complaints03 from "../doc/documents_ complaints/03_zgloszenie-reklamacji.pdf";

function Documents() {
  const [searchTerm, setSearchTerm] = useState("");

  const creditCarDocuments = [
    <>
      <h3>Tabele opłat i&nbsp;oprocentowania</h3>
      <DocumentLink
        href={CreditCar01}
        linkText="Tabela opłat i prowizji Getin Noble Banku S.A. dla konsumentów – kredyty samochodowe"
        spanText="Plik w formacie PDF (rozmiar: 123.9 KB)"
      />
      <DocumentLink
        href={CreditCar02}
        linkText="Tabela opłat i prowizji Getin Noble Banku S.A. dla Klientów Firmowych"
        spanText="Plik w formacie PDF (rozmiar: 473.1 KB)"
      />
      <DocumentLink
        href={CreditCar03}
        linkText="Tabela opłat i prowizji Getin Noble Banku SA dla umów zawartych od 01.01.2021r. przez osoby fizyczne prowadzące działalność gospodarczą, w tym wspólników spółek cywilnych"
        spanText="Plik w formacie PDF (rozmiar: 610.9 KB)"
      />
    </>,
  ];

  const creditMortgageDocuments = [
    <>
      <h3>Dokumenty do obsługi kredytu hipotecznego</h3>

      <DocumentLink
        href={CreditMortgage01}
        linkText="Dyspozycja całkowitej przedterminowej spłaty"
        spanText="Plik w formacie PDF (rozmiar: 194.5 KB)"
      />

      <DocumentLink
        href={CreditMortgage02}
        linkText="Dyspozycja częściowej przedterminowej spłaty"
        spanText="Plik w formacie PDF (rozmiar: 190.8 KB)"
      />

      <DocumentLink
        href={CreditMortgage03}
        linkText="Informacje o Wnioskodawcy - osoba fizyczna"
        spanText="Plik w formacie PDF (rozmiar: 287.3 KB)"
      />

      <DocumentLink
        href={CreditMortgage04}
        linkText="Informacje o Wnioskodawcy - spółka"
        spanText="Plik w formacie PDF (rozmiar: 301.4 KB)"
      />

      <DocumentLink
        href={CreditMortgage05}
        linkText="Karta Informacyjna Poręczyciela - osoba fizyczna"
        spanText="Plik w formacie PDF (rozmiar: 305.6 KB)"
      />

      <DocumentLink
        href={CreditMortgage06}
        linkText="Kosztorys prac budowlanych wraz z harmonogramem"
        spanText="Plik w formacie PDF (rozmiar: 198.1 KB)"
      />

      <DocumentLink
        href={CreditMortgage07}
        linkText="Oświadczenie klienta - do PIT"
        spanText="Plik w formacie PDF (rozmiar: 179.2 KB)"
      />

      <DocumentLink
        href={CreditMortgage08}
        linkText="Oświadczenie Kredytobiorcy – dokumentacja fotograficzna"
        spanText="Plik w formacie PDF (rozmiar: 180.4 KB)"
      />

      <DocumentLink
        href={CreditMortgage09}
        linkText="Oświadczenie kredytobiorcy – o zamieszkiwaniu kredytowanej nieruchomości"
        spanText="Plik w formacie PDF (rozmiar: 178.2 KB)"
      />

      <DocumentLink
        href={CreditMortgage10}
        linkText="Oświadczenie Kredytobiorcy o zawarciu lokaty"
        spanText="Plik w formacie PDF (rozmiar: 181.8 KB)"
      />

      <DocumentLink
        href={CreditMortgage11}
        linkText="Oświadczenie o aktualnie posiadanych zobowiązaniach i kosztach utrzymania"
        spanText="Plik w formacie PDF (rozmiar: 186.6 KB)"
      />

      <DocumentLink
        href={CreditMortgage12}
        linkText="Oświadczenie o sytuacji finansowej"
        spanText="Plik w formacie PDF (rozmiar: 190.2 KB)"
      />

      <DocumentLink
        href={CreditMortgage13}
        linkText="Oświadczenie właściciela nieruchomości"
        spanText="Plik w formacie PDF (rozmiar: 299.6 KB)"
      />

      <DocumentLink
        href={CreditMortgage15}
        linkText="Umowa cesji"
        spanText="Plik w formacie PDF (rozmiar: 280.6 KB)"
      />

      <DocumentLink
        href={CreditMortgage16}
        linkText="Wniosek do rady funduszu o odroczenie/rozłożenie na raty/umorzenie w całości/umorzenie w części należności z tytułu udzielonego wsparcia/pożyczki na spłatę zadłużenia"
        spanText="Plik w formacie PDF (rozmiar: 775.1 KB)"
      />

      <DocumentLink
        href={CreditMortgage17}
        linkText="Wniosek kredytobiorcy o dodatkową weryfikację przez radę funduszu wsparcia kredytobiorców"
        spanText="Plik w formacie PDF (rozmiar: 725.9 KB)"
      />

      <DocumentLink
        href={CreditMortgage18}
        linkText="Wniosek o sporządzenie zaświadczenia/opinii"
        spanText="Plik w formacie PDF (rozmiar: 183.6 KB)"
      />

      <DocumentLink
        href={CreditMortgage19}
        linkText="Wniosek o uzupełnienie kaucji"
        spanText="Plik w formacie PDF (rozmiar: 180.7 KB)"
      />

      <DocumentLink
        href={CreditMortgage20}
        linkText="Wniosek o zmianę danych kontaktowych"
        spanText="Plik w formacie PDF (rozmiar: 179.9 KB)"
      />

      <DocumentLink
        href={CreditMortgage21}
        linkText="Wniosek o zmianę danych kontaktowych (firma)"
        spanText="Plik w formacie PDF (rozmiar: 181.7 KB)"
      />

      <DocumentLink
        href={CreditMortgage22}
        linkText="Wniosek o zmiany warunków umowy"
        spanText="Plik w formacie PDF (rozmiar: 192.9 KB)"
      />

      <DocumentLink
        href={CreditMortgage23}
        linkText="Zaświadczenie od pracodawcy"
        spanText="Plik w formacie PDF (rozmiar: 214.3 KB)"
      />

      <DocumentLink
        href={CreditMortgage24}
        linkText="Zestawienie kosztów remontu, modernizacji, wykończenia"
        spanText="Plik w formacie PDF (rozmiar: 182.3 KB)"
      />

      <DocumentLink
        href={CreditMortgage25}
        linkText="Zgody"
        spanText="Plik w formacie PDF (rozmiar: 308.6 KB)"
      />

      <DocumentLink
        href={CreditMortgage26}
        linkText="Zlecenie inspekcji nieruchomości"
        spanText="Plik w formacie PDF (rozmiar: 184.3 KB)"
      />

      <DocumentLink
        href={CreditMortgage27}
        linkText="Wniosek o zmianę warunków zwrotu wsparcia udzielonego przed dniem 15 maja 2024 r."
        spanText="Plik w formacie PDF (rozmiar: 584.4 KB)"
      />

      <DocumentLink
        href={CreditMortgage28}
        linkText="Wniosek o udzielenie wsparcia, promesy lub pożyczki na spłatę zadłużenia dla kredytobiorcy, który zaciągnął kredyt mieszkaniowy i znajduje się w trudnej sytuacji finansowej"
        spanText="Plik w formacie PDF (rozmiar: 829.1 KB)"
      />

      <h3>Regulaminy</h3>
      <DocumentLink
        href={CreditMortgage29}
        linkText="Regulamin kredytowania hipotecznego Getin Noble Bank S.A."
        spanText="Plik w formacie PDF (rozmiar: 353.4 KB)"
      />
      <DocumentLink
        href={CreditMortgage30}
        linkText="Regulamin Kredytu Hipotecznego"
        spanText="Plik w formacie PDF (rozmiar: 267.1 KB)"
      />

      <DocumentLink
        href={CreditMortgage31}
        linkText="Regulamin Promocji „Łatwa Spłata” zwanej dalej „Promocją” dla posiadaczy konta HIPO i konta HIPO PLUS"
        spanText="Plik w formacie PDF (rozmiar: 147.2 KB)"
      />

      <DocumentLink
        href={CreditMortgage32}
        linkText="Regulamin udzielania kredytów (pożyczek) finansowych Getin Noble Bank S.A."
        spanText="Plik w formacie PDF (rozmiar: 277.2 KB)"
      />

      <DocumentLink
        href={CreditMortgage33}
        linkText="Regulamin udzielania kredytów (pożyczek) finansowych Getin Noble Bank S.A."
        spanText="Plik w formacie PDF (rozmiar: 286.9 KB)"
      />

      <h3>Tabele opłat i oprocentowania</h3>
      <DocumentLink
        href={CreditMortgage34}
        linkText="Tabela opłat i prowizji Getin Noble Bank S.A. dotycząca Kredytów i Pożyczek Hipotecznych i Finansowych"
        spanText="Plik w formacie PDF (rozmiar: 159.6 KB)"
      />
    </>,
  ];

  const investmentsDocuments = [
    <>
      <h3>Dokumenty archiwalne</h3>
      <DocumentLink
        href={Investments01}
        linkText="Regulamin świadczenia usług w zakresie instrumentów finansowych i produktów finansowych - obowiązujący do 2.10.2022 r."
        spanText="Plik w formacie PDF (rozmiar: 545.2 KB)"
      />
      <DocumentLink
        href={Investments02}
        linkText="Regulamin świadczenia usług w zakresie instrumentów finansowych i produktów finansowych - obowiązujący od 18.10.2022 r. do 18.11.2022 r."
        spanText="Plik w formacie PDF (rozmiar: 517.7 KB)"
      />
      <DocumentLink
        href={Investments03}
        linkText="Regulamin świadczenia usług w zakresie instrumentów finansowych i produktów finansowych - obowiązujący od 3.10.2022 r. do 17.10.2022 r."
        spanText="Plik w formacie PDF (rozmiar: 518.4 KB)"
      />
    </>,
  ];

  const complaintsDocuments = [
    <>
      <DocumentLink
        href={Complaints01}
        linkText="Proces obsługi reklamacji dla klientów Getin Noble Bank S.A."
        spanText="Plik w formacie PDF (rozmiar: 352.9 KB)"
      />
      <DocumentLink
        href={Complaints02}
        linkText="Formularz reklamacji skrócony"
        spanText="Plik w formacie PDF (rozmiar: 652.1 KB)"
      />
      <DocumentLink
        href={Complaints03}
        linkText="Zgłoszenie reklamacji"
        spanText="Plik w formacie PDF (rozmiar: 644.5 KB)"
      />
    </>,
  ];

  const filteredCreditCarDocuments = creditCarDocuments.filter(
    (document) =>
      document.linkText &&
      document.linkText.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCreditMortgageDocuments = creditMortgageDocuments.filter(
    (document) =>
      document.linkText &&
      document.linkText.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredInvestmentsDocuments = investmentsDocuments.filter(
    (document) =>
      document.linkText &&
      document.linkText.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredComplaintsDocuments = complaintsDocuments.filter(
    (document) =>
      document.linkText &&
      document.linkText.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const creditCarContent = filteredCreditCarDocuments.map((document) => (
    <DocumentLink
      href={document.href}
      linkText={document.linkText}
      spanText={document.spanText}
    />
  ));

  const creditMortgageContent = filteredCreditMortgageDocuments.map(
    (document) => (
      <DocumentLink
        href={document.href}
        linkText={document.linkText}
        spanText={document.spanText}
      />
    )
  );

  const investmentsContent = filteredInvestmentsDocuments.map((document) => (
    <DocumentLink
      href={document.href}
      linkText={document.linkText}
      spanText={document.spanText}
    />
  ));

  const complaintsContent = filteredComplaintsDocuments.map((document) => (
    <DocumentLink
      href={document.href}
      linkText={document.linkText}
      spanText={document.spanText}
    />
  ));

  useScrollReveal({ selector: "#documents" });

  useScrollReveal({
    selector: ".container",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main>
      <section id="documents">
        <div className="container">
          <header>
            <h1 className="heading">Centrum dokumentów</h1>
          </header>{" "}
          {/* <div className="searchBar">
            <input
              type="text"
              placeholder="Wyszukaj dokument..."
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setSearchTerm(event.target.value);
                  console.log(`Wyszukano: ${event.target.value}`);
                }
              }}
            />
          </div> */}
          <ul className="accordionBox">
            <Accordion
              title="Kredyty Getin Noble Bank S.A. w&nbsp;upadłości"
              content={
                <div>
                  <Accordion
                    title="Kredyt samochodowy"
                    content={creditCarDocuments}
                  />
                  <Accordion
                    title="Kredyt hipoteczny"
                    content={creditMortgageDocuments}
                  />
                </div>
              }
            />
            <Accordion
              title="Inwestycje Getin Noble Bank S.A. w&nbsp;upadłości"
              content={investmentsDocuments}
            />
            <Accordion
              title="Reklamacje Getin Noble Bank S.A. w&nbsp;upadłości"
              content={complaintsDocuments}
            />
          </ul>
          <div className="text">
            <h3>Czym jest SHA256?</h3>
            <p>
              Identyfikator dokumentu SHA256 jest to identyfikator związany
              z&nbsp;dokumentem elektronicznym. Każdy dokument (w tym wszystkie
              kolejne wersje danego dokumentu) ma swój jedyny identyfikator,
              którego nie da się zmienić. Jest to gwarancja niezmienności
              i&nbsp;autentyczności dokumentu.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Documents;
