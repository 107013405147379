import React from "react";
import "../css/IndicesAndBaseRates.css";
import { useScrollReveal } from "../hooks/useScrollReveal";

function IndicesAndBaseRates() {
  useScrollReveal({ selector: "#borrowerSupportFund" });
  useScrollReveal({
    selector: ".whiteBgc",
    duration: 800,
    origin: "bottom",
    distance: "60px",
  });

  return (
    <main id="indicesAndBaseRates">
      <section>
        <div className="container">
          <header>
            <h1 className="heading">Indeksy oraz stawki bazowe</h1>
          </header>
          <div className="whiteBgc">
            <h2>
              Aktualne stawki bazowe dla kredytów hipotecznych udzielonych przez
              Noble&nbsp;Bank&nbsp;S.A. w&nbsp;latach 2006-2009 oraz
              Getin&nbsp;Noble&nbsp;Bank&nbsp;S.A. w&nbsp;latach&nbsp;2010-2017
            </h2>
            <h3 className="additionalMargin">Obowiązuje od 09.06.2024 r.</h3>{" "}
            <div className="gridTable">
              <div className="gridParent eightCol">
                <div className="i1">Nazwa stawki</div>
                <div className="i2">PLN 12M -&nbsp;zmiana co 12m</div>
                <div className="i3">PLN 12M -&nbsp;zmiana co 3m</div>
                <div className="i4">PLN 3M</div>
                <div className="i5">EUR 3M</div>
                <div className="i6">USD 3M</div>
                <div className="i7">CHF 3M</div>
                <div className="i8">JPY 3M</div>
              </div>
              <div className="gridParent eightCol">
                <div className="i9">Wysokość stawki</div>
                <div className="i10">5,86%</div>
                <div className="i11">5,82%</div>
                <div className="i12">5,85%</div>
                <div className="i13">3,82%</div>
                <div className="i14">5,63%</div>
                <div className="i15">1,58%</div>
                <div className="i16">-0,10%</div>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="whiteBgc">
            <h2>
              Indeksy dla umów kredytów hipotecznych udzielonych przez
              Getin&nbsp;Bank&nbsp;S.A. w&nbsp;latach 2004-2009
            </h2>
            <h3 className="additionalMargin">Obowiązuje od 01.08.2024 r.</h3>
            <div className="gridTable">
              <div className="gridParent nineCol">
                <div className="i1">Nazwa indeksu</div>
                <div className="i2">DBZ</div>
                <div className="i3">DBE</div>
                <div className="i4">DBD</div>
                <div className="i5">DBF</div>
                <div className="i6">DBPLN</div>
                <div className="i7">DBEUR</div>
                <div className="i8">DBUSD</div>
                <div className="i9">DBCHF</div>
              </div>

              <div className="gridParent nineCol">
                <div className="i10">Wysokość indeksu</div>
                <div className="i11">5,86%</div>
                <div className="i12">3,82%</div>
                <div className="i13">5,62%</div>
                <div className="i14">1,56%</div>
                <div className="i15">5,85%</div>
                <div className="i16">3,70%</div>
                <div className="i17">5,63%</div>
                <div className="i18">1,40%</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default IndicesAndBaseRates;
